import React from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import "../../sass/index.scss"

const Layout = ({ forceOpaqueHeader, children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Header forceOpaque={forceOpaqueHeader} />
      <Box>
        <main>{children}</main>
      </Box>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  forceOpaqueHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout
