import React, { useState, useRef, useEffect } from "react"
import { Box, Flex, Image, Text, Link } from "rebass"
import { useThemeUI } from "theme-ui"
import { useCycle, motion } from "framer-motion"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import _ from "lodash"
import LogoLight from "../../../assets/images/logo-brand.png"
import LL from "../../../assets/images/logo-w.png"
import MenuToggle from "../menu-toggle"
import { css } from "emotion"

const useDimensions = ref => {
  const dimensions = useRef({ width: 0, height: 0 })

  useEffect(() => {
    dimensions.current.width = ref.current.offsetWidth
    dimensions.current.height = ref.current.offsetHeight
  }, [ref])

  return dimensions.current
}

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(25px at 40px 40px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
}

const Header = ({ forceOpaque }) => {
  const { theme } = useThemeUI()
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)

  const [isOpen, toggleOpen] = useCycle(false, true)

  const links = [
    {
      id: 1,
      name: "Manifesto",
      href: "/manifesto",
    },
    {
      id: 2,
      name: "Services",
      href: "/services",
    },
    {
      id: 3,
      name: "Assets",
      href: "/assets",
    },
    {
      id: 4,
      name: "Press kit",
      href: "/press-kit",
    },
    {
      id: 5,
      name: "Team",
      href: "/team",
    },
    {
      id: 6,
      name: "Contact us",
      href: "/contact-us",
    },
  ]

  const [isTransparent, setIsTransparent] = useState(!forceOpaque)

  useScrollPosition(({ currPos }) => {
    if (!forceOpaque) {
      setIsTransparent(-currPos.y <= 100)
    }
  })

  const Links = () => (
    <>
      {_.map(links, l => (
        <Box
          sx={{
            display: ["block", "block", "block", "flex"],
            height: ["auto", "auto", "auto", "100%"],
            justifyContent: "center",
            alignItems: "center",
          }}
          key={l.name}
        >
          <Link
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              ">div:after": {
                content: "''",
                marginTop: "5px",
                width: 0,
                height: "3px",
                backgroundColor: isTransparent
                  ? theme.colors.white
                  : theme.colors.dark,
                display: "block",
                transition: "width .3s ease",
              },
              ":hover": {
                ">div:after": {
                  width: "100%",
                },
              },
            }}
            px={"16px"}
            href={l.href}
            fontSize={[3, "16px"]}
            color={[
              "dark",
              "dark",
              "dark",
              isTransparent ? theme.colors.white : theme.colors.dark,
            ]}
            fontWeight={"600"}
          >
            <Text>{l.name}</Text>
          </Link>
        </Box>
      ))}
    </>
  )

  return (
    <Flex
      justifyContent={["center", "center", "center", "space-between"]}
      alignItems="center"
      px={[5, 9]}
      sx={{
        height: [70, 100],
        background: isTransparent ? "transparent" : theme.colors.white,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 10,
        transition: "background-color .3s ease",
      }}
    >
      <Flex alignItems="center">
        <Link href="/" sx={{ display: "inline-flex" }}>
          <Image
            style={{ paddingRight: "10px" }}
            src={isTransparent ? LL : LogoLight}
            sx={{ height: 40 }}
          />
        </Link>
        <Link
          href="https://lab24.ilsole24ore.com/leader-della-crescita-2022/"
          sx={{ display: ["none", "none", "inline-flex"] }}
        >
          <Image
            src="https://i.imgur.com/0KhzgsK.png"
            sx={{
              height: 60,
              marginLeft: "18px",
            }}
          />
        </Link>
        <Link
          href="https://www.repubblica.it/dossier/economia/qualita-e-mercati/2022/11/08/news/pmi_portabandiera_del_made_in_italy_sono_800_i_campioni_della_crescita-373454110/"
          sx={{ display: ["none", "none", "inline-flex"] }}
        >
          <Image
            src="https://i.imgur.com/VPcCOLu.png"
            sx={{
              height: 60,
              marginLeft: "18px",
            }}
          />
        </Link>
        <Link
          href="https://www.ft.com/ft1000-2022"
          sx={{ display: ["none", "none", "inline-flex"] }}
        >
          <Image
            src="https://i.imgur.com/kNwdYuN.jpg"
            sx={{
              height: 60,
              marginLeft: "18px",
            }}
          />
        </Link>
      </Flex>

      <Box sx={{ display: ["block", "block", "block", "none"] }}>
        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
          custom={height}
          ref={containerRef}
        >
          <motion.div
            className={css`
              position: fixed;
              top: -5px;
              left: -5px;
              bottom: 0;
              width: 102%;
              background: #fff;

              @media (min-width: ${theme.breakpoints[0]}) {
                top: 0px;
              }
            `}
            variants={sidebar}
          >
            {isOpen && (
              <motion.div
                style={{ marginTop: 64 }}
                initial={false}
                animate={{ opacity: isOpen ? 1 : 0 }}
              >
                <Links />
              </motion.div>
            )}

            <MenuToggle toggle={() => toggleOpen()} />
          </motion.div>
        </motion.nav>
      </Box>

      <Flex
        sx={{
          height: "100%",
          display: [
            "none !important",
            "none !important",
            "none !important",
            "flex !important",
          ],
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Links />
      </Flex>
    </Flex>
  )
}

export default Header
